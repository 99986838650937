import { getClientStatementFilters } from '@/pages/dashboard/management/reports/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Client } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

export default function ClientStatementUrlFields( {
	clientStatement,
	selectedClient,
	variables,
	selectedTab,
	selectedStatuses,
	month,
	year,
	currentTab,
	customDates,
}: {
	clientStatement: boolean,
	selectedClient: Client,
	variables: any,
	selectedTab: any,
	selectedStatuses: any,
	month: Date | string,
	year: Date | string,
	currentTab: number,
	customDates: Date[]
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const timezone = getBrowserTimezone();
	const { staff } = useUserInfo();
	const excludePaidFromStatement = staff?.company?.metadata?.excludePaidFromStatement;
	
	const commerceFilters = selectedClient && [ {
		id   : 'client.id',
		value: {
			$eq : selectedClient.id,
			temp: { checked: true },
		},
	}, {
		id   : 'status',
		value: {
			$in : selectedStatuses,
			temp: { checked: true },
		},
	} ].filter( Boolean );
	
	if ( clientStatement ) {
		const filters = getClientStatementFilters( month, year, selectedTab, selectedClient?.id, timezone, currentTab, customDates, excludePaidFromStatement );
		if ( selectedClient && variables && filters ) {
			return (
				<TextField
					disabled
					fullWidth
					value={`/dashboard/commerce/invoices?s=${filters}`}
					helperText='You can copy the link or send it to your client.'
					InputProps={{
						endAdornment: (
							<Stack direction='row' alignItems='center' ml={1}>
								<Tooltip title='Copy Link'>
									<IconButton
										onClick={() => {
											navigator.clipboard.writeText( `/dashboard/commerce/invoices?s=${filters}` );
											enqueueSnackbar( 'Link copied to clipboard.', { variant: 'success' } );
										}}>
										<ContentCopyIcon fontSize='small'/>
									</IconButton>
								</Tooltip>
								<Tooltip title='View your client statement'>
									<IconButton
										href={`/dashboard/commerce/invoices?s=${filters}`}
										target='_blank'>
										<VisibilityIcon fontSize='small'/>
									</IconButton>
								</Tooltip>
							</Stack>
						),
					}}
				/>
			);
		}
	}
	
	if ( selectedClient && variables ) {
		return (
			<TextField
				fullWidth
				disabled
				value={`${process.env.NEXT_PUBLIC_SITE_URL}/client/${selectedTab}?s=${encodeURIComponent( btoa( JSON.stringify( { filters: commerceFilters } ) ) )}`}
				helperText='You can copy the link or send it to your client.'
				InputProps={{
					endAdornment: (
						<Stack direction='row' alignItems='center' ml={1}>
							<Tooltip title='Copy Link'>
								<IconButton
									onClick={async () => {
										await navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SITE_URL}/client/${selectedTab}?s=${encodeURIComponent(
											btoa( JSON.stringify( { filters: commerceFilters } ) ) )}`,
										);
										enqueueSnackbar( 'Link copied to clipboard.', { variant: 'success' } );
									}}>
									<ContentCopyIcon fontSize='small'/>
								</IconButton>
							</Tooltip>
							<Tooltip title='View your statement'>
								<IconButton
									href={`${process.env.NEXT_PUBLIC_SITE_URL}/client/${selectedTab}?s=${encodeURIComponent(
										btoa( JSON.stringify( { filters: commerceFilters } ) ),
									)}`}
									target='_blank'>
									<VisibilityIcon fontSize='small'/>
								</IconButton>
							</Tooltip>
						</Stack>
					),
				}}
			/>
		);
	}
}
